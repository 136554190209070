export const AddPlus = (props: { color?: string }) => {
  const { color = "#5e6cb2" } = props;
  return (
    <svg
      width="60"
      height="60"
      version="1.1"
      viewBox="0 0 15.875 15.875"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g fill={color} strokeWidth=".26458" aria-label="+">
        <path d="m8.3716 4.6199v2.8784h2.8784v0.8785h-2.8784v2.8784h-0.86816v-2.8784h-2.8784v-0.8785h2.8784v-2.8784z" />
      </g>
      <circle
        cx="7.9375"
        cy="7.9375"
        r="7.5542"
        fill="none"
        stroke={color}
        strokeLinejoin="round"
        strokeWidth=".76676"
      />
    </svg>
  );
};
