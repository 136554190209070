import PlainContainer from "../components/container/PlainContainer";
import {
  EmbeddedCheckout,
  EmbeddedCheckoutProvider,
} from "@stripe/react-stripe-js";
import { Stripe, loadStripe } from "@stripe/stripe-js";
import { useContext } from "react";
import ApiContext from "../context/ApiContext";
import AuthContext from "../context/AuthContext";
const apiKey = process.env.REACT_APP_STRIPE_API_KEY;
export default function StoreView() {
  const {
    actions: { isLoggedIn },
  } = useContext(AuthContext);

  const {
    actions: { createStoreSession },
    data: { email },
  } = useContext(ApiContext);

  const fetchClientSecret = (): Promise<string> => {
    return createStoreSession(
      !isLoggedIn() && email && email.length > 0
        ? `${window.location.origin}/complete`
        : `${window.location.origin}`
    );
  };
  const loadStripePromise = async (): Promise<Stripe | null> => {
    if (apiKey) {
      const stripePromise = await loadStripe(apiKey);
      return stripePromise;
    } else {
      console.log("MISSING STRIPE_API_KEY");
      return null;
    }
  };
  const options = { fetchClientSecret };

  return (
    <PlainContainer>
      <EmbeddedCheckoutProvider stripe={loadStripePromise()} options={options}>
        <EmbeddedCheckout />
      </EmbeddedCheckoutProvider>
    </PlainContainer>
  );
}
