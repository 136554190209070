import axios, { AxiosInstance } from "axios";
import { useNavigate } from "react-router-dom";

class ApiService {
  api: AxiosInstance;
  called: boolean = false;

  constructor() {
    const baseURL = process.env.REACT_APP_API_URL;
    this.api = axios.create({
      baseURL,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    });

    axios.interceptors.response.use(
      (response) => {
        return response;
      },
      (error) => {
        if (error.response && error.response.status === 401) {
          localStorage.removeItem("user");

          const navigate = useNavigate();
          navigate("/signin");
        }
        return Promise.reject(error);
      }
    );
  }
}

const singleton = new ApiService();
export default singleton;
