import { Button, Stack } from "@mui/material";
import { TeamMember } from "../TeamMember";
import { useFormContext, useFieldArray } from "react-hook-form";
import { IHomeFormInput } from "../../interfaces/IHomeFormInput";

export interface TeamAccordianSectionProps {
  expanded: boolean[];
  onChange: (
    index: number,
    event: React.SyntheticEvent,
    expanded: boolean
  ) => void;
}

export function TeamAccordianSection(props: TeamAccordianSectionProps) {
  const { expanded, onChange } = props;

  const { control } = useFormContext<IHomeFormInput>();

  const { append, fields, remove } = useFieldArray({
    control,
    name: "agents",
  });

  const handleRemoveAgent = (index: number) => {
    if (fields.length > 1) {
      remove(index);
    }
  };
  const handleAddAgent = () => {
    append({
      is_away: false,
      first_name: "",
      last_name: "",
      phone: "",
      email: "",
      availability: {},
    });
  };

  return (
    <Stack
      sx={{ width: "100%" }}
      direction="column"
      justifyContent="center"
      alignItems="flex-start"
      spacing={2}
    >
      {fields.map((agent, index: number) => {
        const divid = `agent-wrapper-${index}`;
        return (
          <div key={index} id={divid} className="w-full">
            <TeamMember
              index={index}
              showRemove={fields.length > 1}
              expanded={expanded.length > index ? expanded[index] : false}
              onAgentRemove={() => handleRemoveAgent(index)}
              onExpandChange={(e, expand) => {
                onChange(index, e, expand);
                if (expand)
                  setTimeout(() => {
                    document.getElementById(divid)?.scrollIntoView({
                      behavior: "smooth",
                      block: "center",
                    });
                  }, 500);
              }}
            />
          </div>
        );
      })}

      <Button
        variant="outlined"
        type="button"
        sx={{ width: { xs: "100%", md: 200 } }}
        onClick={handleAddAgent}
      >
        Add more staff
      </Button>
    </Stack>
  );
}
