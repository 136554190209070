import {
  closestCenter,
  DndContext,
  DragOverlay,
  MouseSensor as LibMouseSensor,
  TouchSensor as LibTouchSensor,
  MeasuringStrategy,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import { arrayMove, SortableContext } from "@dnd-kit/sortable";
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import { MouseEvent, TouchEvent, useContext, useState } from "react";
import { useFieldArray, useFormContext } from "react-hook-form";
import ApiContext from "../context/ApiContext";
import { AddPlus } from "../img/AddPlus";
import { AccessLevel } from "../interfaces/IGetUserResult";
import { IHomeFormInput } from "../interfaces/IHomeFormInput";
import { ModelCard } from "./ModelCard";
import { SortableModelCard } from "./SortableModelCard";

// Block DnD event propagation if element have "data-no-dnd" attribute
const handler = ({ nativeEvent: event }: MouseEvent | TouchEvent) => {
  let cur = event.target as HTMLElement;

  while (cur) {
    if (cur.dataset && cur.dataset.noDnd) {
      return false;
    }
    cur = cur.parentElement as HTMLElement;
  }

  return true;
};

export class MouseSensor extends LibMouseSensor {
  static activators = [
    { eventName: "onMouseDown", handler },
  ] as (typeof LibMouseSensor)["activators"];
}

export class TouchSensor extends LibTouchSensor {
  static activators = [
    { eventName: "onTouchStart", handler },
  ] as (typeof LibTouchSensor)["activators"];
}

export function ModelCardLayout() {
  const {
    data: { accessLevel },
  } = useContext(ApiContext);

  const { control, reset, getValues } = useFormContext<IHomeFormInput>();
  const { append, fields, remove } = useFieldArray({
    control,
    name: "cards",
  });

  const handleRemoveCard = (index: number) => {
    if (fields.length > 1) {
      remove(index);
    }
  };

  const handleAddCards = () => {
    append({
      title: "",
      body: "",
      sort_order: fields.length,
    });
  };

  const sensors = useSensors(
    useSensor(MouseSensor, {}),
    useSensor(TouchSensor, {
      activationConstraint: {
        delay: 200,
        tolerance: 6,
      },
    })
  );

  const [activeId, setActiveId] = useState<number | null>(null);

  const handleDragStart = (event: any) => {
    setActiveId(parseInt(event.active.id));
  };

  const handleDragEnd = (event: any) => {
    const { active, over } = event;

    if (active && over && active?.id !== over?.id) {
      const existing = getValues("cards");
      const newItems = arrayMove(existing, active.id, over.id).map(
        (item, index) => ({
          ...item,
          sort_order: index,
        })
      );

      reset({
        ...getValues(),
        cards: newItems,
      });
    }
    setActiveId(null);
  };

  const cardsDisabled = !(
    accessLevel === AccessLevel.PRO ||
    (accessLevel === AccessLevel.FREE &&
      process.env.REACT_APP_STRIPE_ENABLE !== "true")
  );
  const buttonDisabled = fields.length > 9 || cardsDisabled;

  return (
    <Box sx={{ width: "100%", paddingLeft: 2 }}>
      <DndContext
        sensors={sensors}
        collisionDetection={closestCenter}
        onDragStart={handleDragStart}
        onDragEnd={handleDragEnd}
        onDragCancel={() => setActiveId(null)}
        measuring={{
          droppable: {
            strategy: MeasuringStrategy.Always,
          },
        }}
      >
        <Grid
          container
          alignItems="flex-start"
          justifyContent="flex-start"
          spacing={2}
          sx={{ width: "100%" }}
        >
          <SortableContext
            items={fields.map((item, i) => `${i}`)}
            disabled={cardsDisabled}
          >
            {fields.map((item, i) => (
              <Grid item xs={12} sm={6} md={4}>
                <SortableModelCard
                  key={`${i}`}
                  id={`${i}`}
                  modelProps={{
                    index: i,
                    disabled: cardsDisabled,
                    onCardRemove: () => handleRemoveCard(i),
                  }}
                />
              </Grid>
            ))}

            {activeId ? (
              <DragOverlay>
                <ModelCard
                  index={activeId}
                  onCardRemove={() => {}}
                  sx={{
                    cursor: "grabbing",
                    opacity: activeId ? 0.6 : 0,
                    border: "2px dashed grey",
                  }}
                />
              </DragOverlay>
            ) : null}
          </SortableContext>
          <Grid item xs={12} sm={6} md={4}>
            <Card
              sx={{
                backgroundColor: "#F8FAFE",
                cursor: "pointer",
              }}
            >
              <CardContent>
                <Button
                  onClick={handleAddCards}
                  disabled={buttonDisabled}
                  sx={{
                    height: 355,
                    width: "100%",
                  }}
                >
                  <Stack
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                    spacing={2}
                  >
                    <AddPlus color={buttonDisabled ? "#999999" : undefined} />
                    <Typography
                      sx={{ color: buttonDisabled ? "#999999" : undefined }}
                    >
                      Add Card
                    </Typography>
                  </Stack>
                </Button>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </DndContext>
    </Box>
  );
}
