import React, { useContext } from "react";
import { Outlet, Navigate } from "react-router-dom";
import AuthContext from "../context/AuthContext";

const PublicRoutes = () => {
  const {
    actions: { isLoggedIn },
  } = useContext(AuthContext);
  return !isLoggedIn() ? <Outlet /> : <Navigate to="/" />;
};

export default PublicRoutes;
