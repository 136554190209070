export const OpenvoiceStoreLogoPro = () => {
  return (
<svg width="175" height="30" version="1.1" viewBox="0 0 175 30" xmlns="http://www.w3.org/2000/svg">
 <g fill="#333" stroke-width=".42857">
  <path d="m23.025 15.001c0 1.2732-0.15441 2.4333-0.46328 3.4805-0.30137 1.0396-0.77597 1.9361-1.4238 2.6895-0.64787 0.75334-1.4803 1.3334-2.4973 1.7402-1.017 0.3993-2.2337 0.59893-3.65 0.59893-1.3862 0-2.5878-0.19963-3.6048-0.59893-1.0095-0.4068-1.8419-0.9831-2.4973-1.7289-0.65541-0.75339-1.1413-1.6536-1.4577-2.7008-0.31641-1.0472-0.4746-2.2149-0.4746-3.5031 0-1.7176 0.28251-3.2093 0.8475-4.4749 0.57257-1.2732 1.454-2.2563 2.6442-2.9494 1.1903-0.70059 2.7121-1.0509 4.5653-1.0509 1.8834 0 3.4127 0.35031 4.5879 1.0509 1.1827 0.70063 2.0491 1.6875 2.599 2.9607 0.54994 1.2732 0.82491 2.7686 0.82491 4.4862zm-11.368 0c0 0.99442 0.11301 1.8495 0.339 2.5652 0.22603 0.71567 0.58388 1.2656 1.0735 1.6498 0.48969 0.38421 1.13 0.5763 1.9211 0.5763 0.82114 0 1.4728-0.19209 1.9549-0.5763 0.48969-0.38422 0.83996-0.93416 1.0509-1.6498 0.21849-0.71572 0.32773-1.5708 0.32773-2.5652 0-1.4916-0.24861-2.6706-0.74584-3.537-0.49719-0.86636-1.3522-1.2995-2.5651-1.2995-0.8061 0-1.4577 0.19586-1.9549 0.58757-0.48969 0.39176-0.84754 0.94924-1.0735 1.6725-0.21849 0.72321-0.32773 1.582-0.32773 2.5764z"/>
  <path d="m33.739 10.255c1.4163 0 2.5275 0.56876 3.3336 1.7063 0.8061 1.1301 1.2091 2.761 1.2091 4.893 0 1.454-0.2034 2.6782-0.6102 3.6726-0.4068 0.98687-0.96429 1.7327-1.6725 2.2374-0.70059 0.49719-1.4991 0.7458-2.3956 0.7458-0.60266 0-1.115-0.07534-1.5368-0.22599-0.41434-0.15069-0.76089-0.33904-1.0396-0.56503-0.2712-0.22599-0.501-0.45579-0.68932-0.68932h-0.1356c0.03767 0.29379 0.0678 0.6102 0.09039 0.9492 0.03013 0.33904 0.04522 0.73453 0.04522 1.1865v4.6783h-4.4184v-18.363h3.5935l0.62151 1.6272h0.2034c0.21094-0.33146 0.46706-0.63656 0.76843-0.9153 0.30133-0.28628 0.66669-0.51227 1.0961-0.67804 0.43693-0.17327 0.9492-0.25989 1.5368-0.25989zm-1.6611 3.4692c-0.42939 0-0.76839 0.1017-1.017 0.3051-0.24862 0.2034-0.42943 0.51227-0.5424 0.92662-0.10547 0.40684-0.16577 0.91534-0.18081 1.5255v0.35032c0 0.678 0.05272 1.2506 0.15819 1.7176 0.10547 0.45956 0.28629 0.80987 0.54244 1.0509 0.25611 0.23353 0.6102 0.35027 1.0622 0.35027 0.36913 0 0.678-0.10547 0.92662-0.31637 0.24861-0.21094 0.43693-0.54621 0.56499-1.0057 0.1281-0.45956 0.19213-1.066 0.19213-1.8193 0-1.1225-0.14691-1.9173-0.4407-2.3844-0.28629-0.4671-0.70817-0.70063-1.2657-0.70063z"/>
  <path d="m46.748 10.255c1.2429 0 2.3164 0.21849 3.2203 0.65542 0.90429 0.42939 1.6011 1.0773 2.0906 1.9436 0.48986 0.86636 0.73457 1.9512 0.73457 3.2545v1.9662h-7.8986c0.03772 0.67046 0.28629 1.2204 0.74571 1.6498 0.46714 0.42938 1.1524 0.6441 2.0567 0.6441 0.79843 0 1.5291-0.07907 2.1921-0.2373 0.67029-0.15819 1.3599-0.40303 2.0679-0.73453v3.1754c-0.61757 0.32396-1.2921 0.56126-2.0229 0.71194-0.73072 0.15064-1.6534 0.22599-2.7686 0.22599-1.2879 0-2.4407-0.22976-3.4577-0.68931-1.017-0.45956-1.8193-1.1715-2.4069-2.1357-0.58011-0.96429-0.87013-2.2035-0.87013-3.7178 0-1.5368 0.26366-2.7987 0.79102-3.7856 0.52731-0.99441 1.2656-1.7289 2.2147-2.2035 0.94929-0.48214 2.0529-0.72322 3.3111-0.72322zm0.15814 3.0059c-0.51986 0-0.95657 0.16573-1.311 0.49718-0.34629 0.32396-0.54986 0.83623-0.60986 1.5369h3.7967c-0.0077-0.37667-0.08271-0.71949-0.22586-1.0284-0.14314-0.30887-0.35443-0.55367-0.633-0.73448-0.27129-0.18082-0.61029-0.2712-1.017-0.2712z"/>
  <path d="m63.474 10.255c1.3183 0 2.3957 0.3729 3.2319 1.1187 0.83614 0.74584 1.2544 1.9399 1.2544 3.5822v8.3283h-4.4186v-6.9836c0-0.85127-0.12428-1.4916-0.37286-1.921-0.24128-0.43693-0.633-0.65542-1.1751-0.65542-0.83657 0-1.3976 0.34277-1.6839 1.0283-0.28628 0.67804-0.42943 1.6536-0.42943 2.9268v5.6049h-4.4186v-12.803h3.3339l0.59871 1.695h0.16972c0.27128-0.4068 0.59486-0.75334 0.97157-1.0396 0.37672-0.28624 0.81-0.50473 1.2999-0.65542 0.48943-0.15064 1.0359-0.22599 1.6384-0.22599z"/>
  <path d="m85.669 6.7632-5.4124 16.521h-5.2547l-5.3901-16.521h5.0173l2.2487 8.3735c0.08272 0.31642 0.177 0.72322 0.28243 1.2204 0.11314 0.48968 0.21471 0.97933 0.30514 1.469 0.09771 0.48969 0.15814 0.88894 0.18086 1.1978 0.03-0.30887 0.08657-0.70436 0.16928-1.1865 0.08314-0.48214 0.17357-0.96429 0.27128-1.4464 0.10543-0.48969 0.20357-0.89272 0.294-1.2091l2.2937-8.4187z"/>
  <path d="m99.605 16.854c0 1.0698-0.147 2.019-0.441 2.8477-0.29357 0.82869-0.71914 1.5255-1.2767 2.0905-0.55757 0.56499-1.2317 0.99442-2.0229 1.2882-0.79114 0.28629-1.6839 0.42943-2.6781 0.42943-0.92657 0-1.7777-0.14314-2.5539-0.42943-0.76843-0.29378-1.4387-0.72321-2.0113-1.2882-0.57257-0.56503-1.017-1.2618-1.3337-2.0905-0.30857-0.82869-0.46329-1.7779-0.46329-2.8477 0-1.4163 0.25629-2.6141 0.76843-3.5934 0.51986-0.97937 1.2583-1.7252 2.2149-2.2374 0.96428-0.51231 2.1133-0.76843 3.4466-0.76843 1.2283 0 2.3203 0.25612 3.2773 0.76843 0.95657 0.51227 1.7061 1.2581 2.2487 2.2374 0.54986 0.97933 0.825 2.1771 0.825 3.5934zm-8.2946 0c0 0.73076 0.06428 1.3485 0.192 1.8532 0.12814 0.49723 0.33172 0.87767 0.61028 1.1413 0.28628 0.25616 0.65914 0.38422 1.119 0.38422 0.45943 0 0.82457-0.12806 1.0959-0.38422 0.27128-0.26366 0.46714-0.6441 0.58757-1.1413 0.12814-0.50473 0.19243-1.1225 0.19243-1.8532 0-0.73071-0.06428-1.341-0.19243-1.8306-0.12043-0.48964-0.32014-0.85882-0.59872-1.1074-0.27128-0.24861-0.64028-0.37294-1.1074-0.37294-0.67071 0-1.1563 0.27874-1.458 0.83623-0.29357 0.55749-0.44057 1.3824-0.44057 2.4747z"/>
  <path d="m106.75 10.481v12.803h-4.4182v-12.803zm-2.1922-5.1529c0.633 0 1.1829 0.13183 1.65 0.39549 0.47444 0.2637 0.71186 0.78729 0.71186 1.5708 0 0.76089-0.23742 1.2769-0.71186 1.5481-0.46714 0.26366-1.017 0.39549-1.65 0.39549-0.648 0-1.2017-0.13183-1.6611-0.39549-0.45214-0.2712-0.678-0.78724-0.678-1.5481 0-0.78347 0.22586-1.3071 0.678-1.5708 0.45943-0.26366 1.0131-0.39549 1.6611-0.39549z"/>
  <path d="m115.84 23.51c-1.296 0-2.4219-0.22976-3.3789-0.68931-0.95657-0.46706-1.6989-1.1828-2.226-2.1471-0.51986-0.97179-0.78-2.2148-0.78-3.729 0-1.5519 0.29014-2.8213 0.87043-3.8082 0.58757-0.98687 1.3899-1.7139 2.4069-2.1809 1.017-0.4671 2.1694-0.70063 3.4577-0.70063 0.78343 0 1.5257 0.08661 2.2264 0.25989 0.708 0.17327 1.3633 0.41057 1.9663 0.71194l-1.2999 3.2658c-0.52714-0.23353-1.0209-0.41811-1.4803-0.55371-0.45214-0.14314-0.92271-0.21472-1.4126-0.21472-0.44443 0-0.83614 0.11679-1.1751 0.35032s-0.60257 0.58761-0.79114 1.0622c-0.18815 0.4671-0.28243 1.0622-0.28243 1.7854 0 0.7383 0.0943 1.3335 0.28243 1.7854 0.19585 0.45202 0.46328 0.77974 0.80228 0.98314 0.34672 0.2034 0.74229 0.3051 1.1867 0.3051 0.64028 0 1.2771-0.09793 1.9097-0.29379 0.64029-0.2034 1.2467-0.48214 1.8193-0.83623v3.5144c-0.52715 0.34654-1.1224 0.62151-1.7854 0.82491s-1.4349 0.3051-2.3164 0.3051z"/>
  <path d="m128.41 10.255c1.2429 0 2.3164 0.21849 3.2207 0.65542 0.90386 0.42939 1.6007 1.0773 2.0906 1.9436 0.48943 0.86636 0.73457 1.9512 0.73457 3.2545v1.9662h-7.899c0.0377 0.67046 0.28629 1.2204 0.74571 1.6498 0.46714 0.42938 1.1529 0.6441 2.0567 0.6441 0.79843 0 1.5291-0.07907 2.1921-0.2373 0.67072-0.15819 1.3599-0.40303 2.0679-0.73453v3.1754c-0.61757 0.32396-1.2917 0.56126-2.0224 0.71194-0.73071 0.15064-1.6539 0.22599-2.7686 0.22599-1.2883 0-2.4411-0.22976-3.4581-0.68931-1.017-0.45956-1.8193-1.1715-2.4069-2.1357-0.57986-0.96429-0.87-2.2035-0.87-3.7178 0-1.5368 0.26357-2.7987 0.79114-3.7856 0.52714-0.99441 1.2656-1.7289 2.2149-2.2035 0.94886-0.48214 2.0529-0.72322 3.3107-0.72322zm0.15815 3.0059c-0.51986 0-0.95658 0.16573-1.3106 0.49718-0.34671 0.32396-0.55028 0.83623-0.61028 1.5369h3.7967c-7e-3 -0.37667-0.0827-0.71949-0.22585-1.0284-0.14315-0.30887-0.354-0.55367-0.633-0.73448-0.27129-0.18082-0.61029-0.2712-1.017-0.2712z"/>
 </g>
 <g fill="#5e6cb2">
  <ellipse cx="3.3834" cy="6.3092" rx="3.3834" ry="3.3844" stroke-width=".42857"/>
  <path d="m20.096 28.29c0.15133 0.39446-0.04513 0.83893-0.44679 0.97007-2.2879 0.747-4.7258 0.93338-7.1075 0.53811-2.6335-0.43706-5.1027-1.5694-7.1525-3.2802-2.0498-1.7108-3.6059-3.9378-4.5075-6.4513-0.90165-2.5134-1.1162-5.222-0.62146-7.8461 0.11594-0.61491 0.26984-1.22 0.46013-1.812 0.42736 0.27844 0.90096 0.49179 1.4068 0.62601-0.14821 0.48124-0.26976 0.97179-0.3636 1.4695-0.44427 2.3564-0.25161 4.7887 0.55806 7.0457 0.80967 2.257 2.207 4.2569 4.0477 5.7931 1.8407 1.5362 4.058 2.5531 6.4229 2.9455 2.1128 0.35066 4.275 0.19157 6.3078-0.45913 0.40247-0.12883 0.84458 0.066 0.996 0.46054zm-12.535-24.52c0.97911-0.64862 2.0432-1.1692 3.1668-1.5448 2.2736-0.76008 4.7089-0.89926 7.0542-0.40316 2.0954 0.44322 4.0511 1.3792 5.7074 2.7258 0.3279 0.26657 0.8106 0.24613 1.0953-0.066171 0.28457-0.31217 0.26348-0.79758-0.0627-1.066-1.8588-1.5293-4.0615-2.5912-6.4235-3.0908-2.6118-0.55246-5.3237-0.39747-7.8556 0.44896-1.3099 0.43792-2.5471 1.0527-3.6792 1.8228 0.39086 0.33529 0.72819 0.73128 0.99733 1.1733z" clip-rule="evenodd" fill-rule="evenodd" stroke-width=".42857"/>
  <path d="m175.76 18.186c-0.0922 0.12704-0.22776 0.1863-0.26987 0.28533-0.63957 1.504-1.8531 2.2633-3.4033 2.2685-10.831 0.03645-21.663 0.03235-32.494 0.0074-1.9804-0.0046-3.6935-1.6648-3.692-3.7217 2e-3 -3.6105 7e-5 -7.221 1e-3 -10.832 3.7e-4 -1.7859 1.3597-3.3618 3.1413-3.6254 0.42685-0.063155 0.86148-0.10381 1.2926-0.10404 10.411-0.00566 20.822-0.0034 31.233-0.00536 1.788-3.367e-4 3.1896 0.63511 3.9552 2.3512 0.0403 0.090299 0.12024 0.1629 0.20931 0.2087 0.0275 4.3492 0.0275 8.7334 0.0275 13.167m-14.708-4.132c0.63537 1.6127 1.8372 2.5306 3.5296 2.7674 0.66918 0.09366 1.3698 0.10357 2.0402 0.02126 1.941-0.23831 3.2433-1.2958 3.8064-3.1958 0.34935-1.1787 0.35675-2.3785 0.18507-3.5878-0.31674-2.2309-1.6754-3.6431-3.9051-3.9763-0.58273-0.087081-1.1898-0.075887-1.7804-0.03402-2.0665 0.14651-3.554 1.3761-4.0555 3.3737-0.38274 1.5243-0.3499 3.0507 0.17975 4.6315m-1.8098-4.2368c0.10037-1.8245-0.46933-2.8591-1.9192-3.3218-1.7395-0.55504-3.5342-0.26214-5.3247-0.33677v10.572h2.2072v-3.945c0.99063-0.22423 1.1338-0.16748 1.6188 0.6121 0.62901 1.0112 1.2541 2.0249 1.8926 3.0301 0.0846 0.13326 0.23441 0.31279 0.36019 0.31787 0.74361 0.03001 1.489 0.01479 2.3297 0.01479-1.0587-1.5753-2.0604-3.0658-3.0861-4.5919 0.97579-0.49165 1.6888-1.1748 1.9217-2.3511m-13.872 4.3723v-1.2166c0.58319 0 1.106 0.02601 1.6255-0.0047 1.8468-0.10914 3.0558-1.0763 3.3741-2.6728 0.37764-1.8943-0.40393-3.3942-2.0854-3.8755-1.6791-0.48059-3.401-0.17401-5.1212-0.26638v10.578h2.1516c0.0232-0.06272 0.0447-0.09409 0.0449-0.12559 5e-3 -0.77071 7e-3 -1.5414 0.0105-2.4163z" stroke-width=".10517"/>
 </g>
 <g fill="#fff" stroke-width=".10517">
  <path d="m161.04 14.014c-0.51808-1.5413-0.55092-3.0677-0.16818-4.592 0.50159-1.9976 1.9891-3.2272 4.0555-3.3737 0.59056-0.041868 1.1977-0.053062 1.7804 0.03402 2.2297 0.3332 3.5884 1.7454 3.9051 3.9763 0.17167 1.2093 0.16428 2.4091-0.18507 3.5878-0.56317 1.9-1.8655 2.9575-3.8064 3.1958-0.67033 0.0823-1.371 0.07239-2.0402-0.02126-1.6925-0.23687-2.8942-1.1548-3.5412-2.8069m1.9656-2.7678c0.11476 0.77159 0.13018 1.5755 0.36814 2.307 0.33576 1.0321 1.1926 1.4462 2.2551 1.4568 1.0512 0.01057 1.9179-0.34588 2.3265-1.3636 0.58283-1.4516 0.58112-2.9439-2e-3 -4.396-0.38932-0.97054-1.2081-1.3494-2.224-1.3571-1.0052-0.00761-1.8664 0.31369-2.2673 1.2808-0.25445 0.61371-0.30834 1.3106-0.45706 2.0722z"/>
  <path d="m159.23 9.8625c-0.22631 1.1308-0.93928 1.814-1.9151 2.3057 1.0257 1.5262 2.0274 3.0167 3.0861 4.5919-0.84067 0-1.5861 0.01522-2.3297-0.01479-0.12578-0.0051-0.27555-0.18461-0.36019-0.31787-0.63847-1.0052-1.2636-2.0189-1.8926-3.0301-0.48493-0.77959-0.62814-0.83633-1.6188-0.6121v3.945h-2.2072v-10.572c1.7905 0.074621 3.5852-0.21827 5.3247 0.33677 1.4499 0.46264 2.0196 1.4973 1.9126 3.3672m-5.0319-0.92841v2.0302c0.73494-0.10887 1.3852-0.14502 1.9991-0.31364 0.57468-0.15785 0.83833-0.69741 0.79709-1.3324-0.0394-0.60658-0.3123-1.0165-0.90744-1.1387-0.60206-0.12362-1.227-0.13592-1.8887-0.20164v0.95624z"/>
  <path d="m145.37 14.241c-3e-3 0.82277-5e-3 1.5935-0.0105 2.3642 0 0.03151-0.0217 0.06287-0.0449 0.12559h-2.1516v-10.578c1.7201 0.092367 3.4421-0.21421 5.1212 0.26638 1.6815 0.48127 2.463 1.9812 2.0854 3.8755-0.31828 1.5965-1.5273 2.5636-3.3741 2.6728-0.51949 0.0307-1.0423 0.0047-1.6255 0.0047v1.2687m0.28421-6.2144c-0.0939 0.071933-0.2682 0.14232-0.26961 0.21603-0.0184 0.9564-0.0122 1.9133-0.0122 2.9804 0.64095-0.07849 1.2208-0.08831 1.7658-0.23109 0.75064-0.19663 1.101-0.8203 1.0214-1.6195-0.08-0.80295-0.48822-1.233-1.3031-1.3222-0.36389-0.03984-0.73448-0.018288-1.2024-0.023676z"/>
 </g>
 <g fill="#5e6cb2" stroke-width=".10517">
  <path d="m163.01 11.196c0.14626-0.71059 0.20015-1.4074 0.4546-2.0212 0.40095-0.96707 1.2621-1.2884 2.2673-1.2808 1.0159 0.00769 1.8347 0.38659 2.224 1.3571 0.5825 1.4521 0.58421 2.9444 2e-3 4.396-0.40864 1.0178-1.2753 1.3742-2.3265 1.3636-1.0626-0.01069-1.9194-0.42471-2.2551-1.4568-0.23796-0.73151-0.25338-1.5354-0.36567-2.358z"/>
  <path d="m154.2 8.8827v-0.90485c0.66175 0.065716 1.2867 0.078022 1.8887 0.20164 0.59514 0.12219 0.86808 0.53215 0.90744 1.1387 0.0412 0.63502-0.22241 1.1746-0.79709 1.3324-0.61387 0.16862-1.2641 0.20477-1.9991 0.31364v-2.0816z"/>
  <path d="m145.7 8.0266c0.41773 0.00582 0.78832-0.015702 1.1522 0.024144 0.81486 0.089221 1.2231 0.51927 1.3031 1.3222 0.0796 0.79918-0.27078 1.4228-1.0214 1.6195-0.54503 0.14277-1.1249 0.1526-1.7658 0.23109 0-1.0672-6e-3 -2.024 0.0122-2.9804 2e-3 -0.073707 0.17569-0.1441 0.3198-0.21649z"/>
 </g>
</svg>
  );
};
