import {
  Box,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import { useMediaMobile } from "../utils/utils";
import LandingViewForm from "../components/LandingViewForm";


export default function LandingView() {
  const isMobile = useMediaMobile();
  return (
    <Box sx={{ maxWidth: 1280, margin: "auto" }}>
      <Grid container spacing={2}>
        <Grid item xs={12} lg={6} order={{ xs: 2, md: 1 }}>
          <Stack direction="column" spacing={2}>
            {!isMobile && (
              <Typography variant="h1">
                Instantly set up your Al-powered phone system.
              </Typography>
            )}
            <Typography variant="body2">
              <i>In seconds</i>, receive a dedicated business phone number and
              an Al receptionist.
            </Typography>

            <Typography variant="h3">
              <span className="underlined">No credit</span>card required.{" "}
            </Typography>
            <Typography variant="body2" sx={{ paddingBottom: 5 }}>
              Our intelligent AI receptionist: efficiently manages calls,
              accurately takes messages, and effectively screens out robocalls,
              ensuring your communications are smooth and uninterrupted 24/7.
              <br />
              <br />
              Gain full control to customize and train the Al, tailoring your
              business phone system to meet your specific needs.
            </Typography>
          </Stack>
        </Grid>
        <Grid item xs={12} lg={6} order={{ xs: 1, md: 2 }}>
          <Typography
            variant="h2"
            sx={{ margin: 2, marginTop: 1, fontSize: "28px" }}
          >
            Create your AI Phone System in Seconds.
          </Typography>
          <LandingViewForm />
        </Grid>
      </Grid>
    </Box>
  );
}
