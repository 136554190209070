/* eslint-disable */
import { Box } from "@mui/material";
import { EmblaCarouselType, EmblaOptionsType } from "embla-carousel";
import useEmblaCarousel from "embla-carousel-react";
import React, { ReactElement, useCallback, useEffect } from "react";
import "react-multi-carousel/lib/styles.css";
import { useMediaMobile } from "../../../../utils/utils";

type Props = {
  slides: ReactElement[];
  options?: EmblaOptionsType;
  selected?: number;
  onSelect?: (index: number) => void;
};

export const EmblaCarousel: React.FC<Props> = (props) => {
  const isMobile = useMediaMobile();
  const { slides, options, selected, onSelect } = props;

  const [emblaRef, emblaApi] = useEmblaCarousel(options);
  const selectEvent = useCallback((emblaApi: EmblaCarouselType) => {
    if (onSelect) onSelect(emblaApi.selectedScrollSnap());
  }, []);
  useEffect(() => {
    if (emblaApi) {
      emblaApi.on("select", selectEvent);
    }
  }, [emblaApi]);

  useEffect(() => {
    if (emblaApi) {
      if (selected !== undefined && emblaApi.selectedScrollSnap() != selected) {
        emblaApi.scrollTo(selected);
      }
    }
  }, [emblaApi, selected]);

  return (
    <Box sx={{ width: isMobile ? "100vw" : undefined }}>
      <section style={{ width: "100%" }}>
        <Box
          sx={{
            overflow: "hidden",
          }}
          ref={emblaRef}
        >
          <Box
            sx={{
              backfaceVisibility: "hidden",
              display: "flex",
              touchAction: "pan-y",
            }}
          >
            {slides.map((element, index) => (
              <Box
                sx={{
                  flex: "0 0 75%",
                  minWidth: 310,
                  maxWidth: 310,
                }}
                key={index}
              >
                {element}
              </Box>
            ))}
          </Box>
        </Box>
      </section>
    </Box>
  );
};
