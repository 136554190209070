
export const RightArrow = (props: { color?: string }) => {
  const { color = "white" } = props;

  return (
    <svg width="25" height="20" viewBox="0 0 24 24" fill="none">
      <path
        d="M9 5l7 7-7 7"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
      />
    </svg>
  );
};
