import React, { ReactElement } from 'react';

export const ShowIcon = (): ReactElement => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d={
        'M15.1615 12.0531C15.1615 13.7991 13.7455 15.2141 11.9995' +
        ' 15.2141C10.2535 15.2141 8.8385 13.7991 8.8385 12.0531C8.8385 10.3061' +
        ' 10.2535 8.89111 11.9995 8.89111C13.7455 8.89111 15.1615' +
        ' 10.3061 15.1615 12.0531Z'
      }
      stroke="#BDBCC5"
      strokeWidth="1.3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d={
        'M11.9981 18C15.7031 18 19.092 15.7502 21 12C19.092' +
        ' 8.24979 15.7031 6 11.9981 6H12.0019C8.29686' +
        ' 6 4.908 8.24979 3 12C4.908 15.7502 8.29686 18 12.0019 18H11.9981Z'
      }
      stroke="#BDBCC5"
      strokeWidth="1.3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
