import React, { useCallback, useEffect, useState } from "react";
import { AuthUser } from "../interfaces/AuthUser";
import { default as ApiService } from "../context/ApiServices";
import { IAgentCreate } from "../interfaces/Agent";
import { jwtDecode } from "jwt-decode";
import { ICityResult } from "../interfaces/ICityResult";

const AuthContext = React.createContext<{
  actions: {
    login: (username: string, password: string) => Promise<any>;
    logout: () => void;
    createGuest: (
      location: ICityResult,
      businessName: string,
      voice: string,
      agents: IAgentCreate[],
      referer?: string
    ) => Promise<any>;
    createGuestAccount: (
      username: string,
      password: string,
      referer?: string
    ) => Promise<any>;
    updateAccount: (
      username: string,
      password: string,
      referer?: string
    ) => Promise<any>;
    forgotPassword: (email: string) => Promise<any>;
    resetPassword: (password: string, token: string) => Promise<any>;
    isLoggedIn: (t?: AuthUser | null | undefined) => boolean;
    expireAt: (authToken: AuthUser | null | undefined) => number;
    checkToken: () => AuthUser | null;
  };
  data: {
    authToken: AuthUser | null | undefined;
  };
}>({
  actions: {
    login: async () => undefined,
    logout: () => undefined,
    createGuest: async () => undefined,
    createGuestAccount: async () => undefined,
    updateAccount: async () => undefined,
    forgotPassword: async () => undefined,
    resetPassword: async () => undefined,
    isLoggedIn: (t?: AuthUser | null | undefined) => false,
    expireAt: (_) => -1,
    checkToken: () => null,
  },
  data: {
    authToken: undefined,
  },
});

interface AuthContextProviderProps {
  children: React.ReactNode;
}

export function AuthContextProvider(
  props: AuthContextProviderProps
): React.ReactElement {
  const { children } = props;
  const [authToken, setAuthToken] = useState<AuthUser | null>();

  const checkToken = useCallback(() => {
    const json = localStorage.getItem("user");

    if (json) {
      const token = JSON.parse(json);
      setAuthToken(token);
      return token;
    } else setAuthToken(null);
    return null;
  }, []);
  useEffect(() => {
    checkToken();
  }, [checkToken]);

  const expireAt = useCallback((token: AuthUser | null | undefined) => {
    if (token) {
      const decoded = jwtDecode(token.access_token);
      if (decoded && decoded.exp) return decoded.exp * 1000;
    }
    return -1;
  }, []);

  const isLoggedIn = useCallback(
    (t?: AuthUser | null | undefined) => {
      const token = t ? t : authToken;
      if (!token) return false;

      const exp = expireAt(token);
      if (exp < 0 || Date.now() >= exp) {
        return false;
      }
      return true;
    },
    [authToken, expireAt]
  );

  const logout = useCallback(() => {
    setAuthToken(undefined);
    localStorage.removeItem("user");
  }, []);

  const login = useCallback(
    (username: string, password: string) => {
      return new Promise(async (resolve, reject) => {
        try {
          const response = await ApiService.api.post(
            `/login`,
            {
              username,
              password,
            },
            {
              headers: {
                "Content-Type": "application/x-www-form-urlencoded",
              },
            }
          );

          const token: AuthUser = response.data;
          setAuthToken(token);
          localStorage.setItem("user", JSON.stringify(token));
          resolve(response);
        } catch (e) {
          logout();
          reject(e);
        }
      });
    },
    [logout]
  );

  const createGuest = useCallback(
    (
      location: ICityResult,
      businessName: string,
      voice: string,
      agents: IAgentCreate[],
      referer?: string
    ) => {
      return new Promise(async (resolve, reject) => {
        try {
          const response = await ApiService.api.post(
            `/user`,
            {
              location: location,
              name: businessName,
              voice: voice,
              users: agents,
              referer,
            },
            {
              headers: {
                "Content-Type": "application/json",
              },
            }
          );

          const token: AuthUser = response.data;
          setAuthToken(token);
          localStorage.setItem("user", JSON.stringify(token));
          resolve(token);
        } catch (e: any) {
          reject(e);
        }
      });
    },
    []
  );

  const createGuestAccount = useCallback(
    (username: string, password: string, referer?: string) => {
      return new Promise(async (resolve, reject) => {
        try {
          const response = await ApiService.api.post(
            `account`,
            { username, password, referer },
            {
              headers: {
                "Content-Type": "application/json",
              },
            }
          );
          const token: AuthUser = response.data;
          setAuthToken(token);
          localStorage.setItem("user", JSON.stringify(token));
          resolve(token);
        } catch (e: any) {
          reject(e);
        }
      });
    },
    []
  );

  const updateAccount = useCallback(
    (username: string, password: string, referer?: string) => {
      return new Promise(async (resolve, reject) => {
        if (!isLoggedIn()) {
          reject("You are not logged in. Please log in and try again");
        }
        try {
          const response = await ApiService.api.put(
            `account`,
            { username, password, referer },
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${authToken?.access_token}`,
              },
            }
          );
          const token: AuthUser = response.data;
          setAuthToken(token);
          localStorage.setItem("user", JSON.stringify(token));
          resolve(token);
        } catch (e: any) {
          reject(e);
        }
      });
    },
    [authToken, isLoggedIn]
  );

  const forgotPassword = useCallback((email: string) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await ApiService.api.post(
          `forgot-password`,
          { email },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        resolve(response);
      } catch (e: any) {
        reject(e);
      }
    });
  }, []);

  const resetPassword = useCallback((password: string, token: string) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await ApiService.api.post(
          `reset-password`,
          { new_password: password, token },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        resolve(response);
      } catch (e: any) {
        reject(e);
      }
    });
  }, []);

  return (
    <AuthContext.Provider
      value={{
        actions: {
          login,
          logout,
          createGuest,
          createGuestAccount,
          updateAccount,
          forgotPassword,
          resetPassword,
          isLoggedIn,
          expireAt,
          checkToken,
        },
        data: {
          authToken,
        },
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}
export default AuthContext;
