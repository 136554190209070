/* eslint-disable */
import {
  ToggleButton,
  ToggleButtonGroup,
  ToggleButtonGroupProps,
  useTheme,
} from "@mui/material";
import React, { useState } from "react";

type Props = {
  selected?: number;
  labels: string[];
  onSelectTab?: (index: number) => void;
} & ToggleButtonGroupProps;

export function AuthTabs(props: Props) {
  const { selected, labels, onSelectTab, ...restProps } = props;
  const [tabIndex, setTabIndex] = useState<number>(selected || 0);
  const theme = useTheme();
  const countryPanelItem = {
    backgroundColor: "transparent",
    padding: 1,
    textTransform: "unset",
    minWidth: "100px",
    color: theme.palette.primary.main,
    border: "none",
    "&.Mui-selected": {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white,
      border: `1px solid ${theme.palette.primary.main}`,
      borderRadius: 20,
    },
    "&.Mui-selected:hover": {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white,
      border: `1px solid ${theme.palette.primary.main}`,
      borderRadius: 20,
    },
  };
  //controlled vs uncontrolled element!
  const selectedTab = onSelectTab && selected !== undefined ? selected : tabIndex;

  return (
    <ToggleButtonGroup
      sx={{
        background: "white",
        border: `1px solid ${theme.palette.primary.main}`,
        borderRadius: 20,
      }}
      value={selectedTab}
      exclusive
      onChange={(_, v) => {
        if (selectedTab !== v) {
          setTabIndex(v);
          if (onSelectTab) onSelectTab(v);
        }
      }}
      aria-label="Platform"
      {...restProps}
    >
      {labels.map((element, index) => (
        <ToggleButton value={index} sx={countryPanelItem}>
          {element}
        </ToggleButton>
      ))}
    </ToggleButtonGroup>
  );
}
