import { Stack, useTheme } from "@mui/material";
import "react-multi-carousel/lib/styles.css";
import { AuthTabs } from "./AuthTabs";
import { EmblaCarousel } from "./store/EmblaCarousel";
import { StoreCard } from "./store/StoreCard";
import { useMediaMobile } from "../../../utils/utils";

interface StoreFormProps {
  planIndex: number;
  onIndexChange: (i: number) => void;
}
export default function StoreForm(props: StoreFormProps) {
  const { planIndex, onIndexChange } = props;
  const theme = useTheme();
  const isMobile = useMediaMobile();

  const cards = [
    <StoreCard
      key="card-free"
      selected={planIndex === 0}
      onClick={() => onIndexChange(0)}
    />,
    <StoreCard
      key="card-pro"
      selected={planIndex === 1}
      onClick={() => onIndexChange(1)}
      cardVariant="pro"
    />,
  ];
  const onSelect = (index: number) => {
    onIndexChange(index);
  };

  const carouselBreakpoint : any = {};
  carouselBreakpoint[`(min-width: ${theme.breakpoints.values.md}px)`] = {
    slidesToScroll: 2,
    active: false,
  };

  return (
    <Stack
      direction="column"
      justifyContent={!isMobile ? "space-around" : "center"}
      alignItems={!isMobile ? "flex-end" : "center"}
      spacing={2}
    >
      {isMobile && (
        <AuthTabs
          selected={planIndex}
          onSelectTab={onSelect}
          labels={["Free", "PRO"]}
        />
      )}

      <EmblaCarousel
        slides={cards}
        options={{
          breakpoints: carouselBreakpoint,
          containScroll: false,
        }}
        selected={planIndex}
        onSelect={onSelect}
      />
    </Stack>
  );
}
