import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  IconButton,
  Paper,
  TextField,
} from "@mui/material";
import { useCallback, useRef } from "react";
import { Close } from "@mui/icons-material";
import AvailabilityAccordian from "./AvailabilityAccordian";
import { Controller, useFormContext } from "react-hook-form";
import { IHomeFormInput } from "../interfaces/IHomeFormInput";
import { useMediaMobile } from "../utils/utils";

interface TeamMemberProps {
  index: number;
  showRemove: boolean;
  expanded: boolean;
  onAgentRemove: () => void;
  onExpandChange: (event: React.SyntheticEvent, expanded: boolean) => void;
}

export function TeamMember(props: TeamMemberProps) {
  const {
    index,
    showRemove,
    expanded,

    onAgentRemove,
    onExpandChange,
  } = props;
  const isMobile = useMediaMobile();
  const accordianRef = useRef<HTMLDivElement | null>();

  const handleRemoveAgent = useCallback(() => {
    onAgentRemove();
  }, [onAgentRemove]);

  const handleExpandChange = (
    event: React.SyntheticEvent,
    expanded: boolean
  ) => {
    onExpandChange(event, expanded);
  };

  const {
    control,
    formState: { errors },
  } = useFormContext<IHomeFormInput>();

  return (
    <Paper
      elevation={2}
      sx={{ width: "100%", backgroundColor: "#F8FAFE", padding: 2 }}
    >
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="flex-start"
        spacing={1}
      >
        <Grid item xs={12} md={8}>
          <Grid
            container
            justifyContent="flex-start"
            alignItems="flex-start"
            spacing={1}
          >
            <Grid item xs={12} md={6}>
              <Controller
                control={control}
                name={`agents.${index}.first_name`}
                render={({ field }) => {
                  const e =
                    errors?.agents?.at !== undefined &&
                    errors?.agents.at(index) !== undefined &&
                    errors?.agents?.at(index)?.first_name?.message;
                  return (
                    <TextField
                      {...field}
                      error={e !== undefined && e !== false}
                      helperText={e ? e.toString() : "　"}
                      sx={{
                        width: "100%",
                        "& .MuiOutlinedInput-input": { background: "white" },
                      }}
                      type="text"
                      placeholder="First Name"
                      inputProps={{
                        className: "bg-white",
                        maxLength: 50,
                      }}
                    />
                  );
                }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Controller
                control={control}
                name={`agents.${index}.last_name`}
                render={({ field }) => {
                  const e =
                    errors?.agents?.at !== undefined &&
                    errors?.agents.at(index) !== undefined &&
                    errors?.agents?.at(index)?.last_name?.message;
                  return (
                    <TextField
                      {...field}
                      error={e !== undefined && e !== false}
                      helperText={e ? e.toString() : "　"}
                      sx={{
                        width: "100%",
                        "& .MuiOutlinedInput-input": { background: "white" },
                      }}
                      type="text"
                      placeholder="Last Name"
                      inputProps={{
                        maxLength: 50,
                      }}
                    />
                  );
                }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Controller
                control={control}
                name={`agents.${index}.phone`}
                render={({ field }) => {
                  const e =
                    errors?.agents?.at !== undefined &&
                    errors?.agents.at(index) !== undefined &&
                    errors?.agents?.at(index)?.phone?.message;
                  return (
                    <TextField
                      {...field}
                      error={e !== undefined && e !== false}
                      helperText={e ? e.toString() : "　"}
                      sx={{
                        width: "100%",
                        "& .MuiOutlinedInput-input": { background: "white" },
                      }}
                      type="text"
                      placeholder="Phone"
                      inputProps={{
                        maxLength: 50,
                      }}
                    />
                  );
                }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Controller
                control={control}
                name={`agents.${index}.email`}
                render={({ field }) => {
                  const e =
                    errors?.agents?.at !== undefined &&
                    errors?.agents.at(index) !== undefined &&
                    errors?.agents?.at(index)?.email?.message;
                  return (
                    <TextField
                      {...field}
                      error={e !== undefined && e !== false}
                      helperText={e ? e.toString() : "　"}
                      sx={{
                        width: "100%",
                        "& .MuiOutlinedInput-input": { background: "white" },
                      }}
                      type="text"
                      placeholder="Email (Add to receive voicemails)"
                      inputProps={{
                        maxLength: 50,
                      }}
                    />
                  );
                }}
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} md={showRemove ? 3 : 4}>
          <Controller
            control={control}
            name={`agents.${index}.is_away`}
            render={({ field }) => {
              return (
                <FormControlLabel
                  sx={{ width: "100%" }}
                  control={
                    <Checkbox
                      sx={{
                        color: "#DEDEDE",
                      }}
                      checked={field.value}
                      onChange={(_, checked) => field.onChange(checked)}
                    />
                  }
                  label="Unavailable. Take a message"
                />
              );
            }}
          />
        </Grid>

        {showRemove && (
          <Grid item xs={12} md={1}>
            {!isMobile ? (
              <IconButton
                disabled={!showRemove}
                onClick={() => handleRemoveAgent()}
              >
                <Close />
              </IconButton>
            ) : (
              <Button
                sx={{
                  width: "100%",
                }}
                variant="outlined"
                onClick={() => handleRemoveAgent()}
              >
                Remove team member
              </Button>
            )}
          </Grid>
        )}
      </Grid>

      <div className="w-full col-span-12 order-last rounded-md ring-transparent outline-none focus:outline-none bg-white/10 focus:border-white/80 transition-all focus:bg-white/5">
        <Box ref={accordianRef} sx={{ marginTop: 1 }}>
          <AvailabilityAccordian
            index={index}
            expanded={expanded}
            onChange={handleExpandChange}
          />
        </Box>
      </div>
    </Paper>
  );
}
