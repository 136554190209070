import React, { ReactElement } from 'react';

export const HideIcon = (): ReactElement => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      d={
        'M9.89377 13.9999C9.31877 13.4259 9.086 12.7632 9.086 11.8882C9.086' +
        ' 10.1352 10.498 8.72217 12.25 8.72217C13.117 8.72217 13.915 9.07317' +
        ' 14.48 9.64717'
      }
      stroke="#BDBCC5"
      strokeWidth="1.3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15.4253 12.5235C15.1205 13.7982 14.0483 14.7569 12.7481 14.9173"
      stroke="#BDBCC5"
      strokeWidth="1.3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d={
        'M6.74811 16C5.22487 15.0141 3.93487 13.574 3 11.7786C3.94447 9.97531' +
        ' 5.24311 8.5273 6.77595 7.53347C8.29919 6.53964 10.0566 6 11.8784' +
        ' 6C13.7107 6 15.4672 6.54756 17 7.5485'
      }
      stroke="#BDBCC5"
      strokeWidth="1.3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d={
        'M19.3176 9C19.9599 9.78733 20.5248 10.6961 21 11.71C19.1636 15.6354' +
        ' 15.9183 18 12.3639 18C11.5582 18 10.2637 17.7352 9.5 17.5'
      }
      stroke="#BDBCC5"
      strokeWidth="1.3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M21.4128 4.38813L4.31189 18.7128"
      stroke="#BDBCC5"
      strokeWidth="1.3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
