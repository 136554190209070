import { Box, Paper, PaperProps, Stack, Typography } from "@mui/material";
import React, { ReactElement } from "react";
import { theme } from "../../../../theme";
import { Logo } from "../../../../img/Logo";
import { OpenvoiceStoreLogo } from "../../../../img/OpenvoiceStoreLogo";
import { OpenvoiceStoreLogoPro } from "../../../../img/OpenvoiceStoreLogoPro";

type Props = {
  cardVariant?: "free" | "pro";
  selected?: boolean;
} & PaperProps;

interface ProductDefinition {
  logo: ReactElement;
  subtitle: string;
  price: string;
  priceSubtitle: string;
  description: ReactElement;
}

const freeCard: ProductDefinition = {
  logo: <OpenvoiceStoreLogo />,
  subtitle: "Intelligent AI receptionist: efficiently manages calls",
  price: "Free",
  priceSubtitle: "limited time",
  description: (
    <ul style={{margin: 8}}>
      <li>
        Business Phone System with automated attendant for efficient call
        routing
      </li>
      <li>Manages calls</li>
      <li>Take messages</li>
      <li>Screens out robocalls</li>
    </ul>
  ),
};

const proCard: ProductDefinition = {
  logo: <OpenvoiceStoreLogoPro />,
  subtitle:
    "All OpenVoice Standard features PLUS Full White Glove Service & Support.",
  price: "$200",
  priceSubtitle: "per year",
  description: (
    <>
      <Box>
        <ul style={{margin: 8}}>
          <li>Ad free calling</li>
          <li>Keep your existing business phone number. Porting included.</li>
          <li>Expanded AI customizations</li>
          <li>Optimized voicemail settings</li>
          <li>Customized AI training models</li>
          <li>Plus all of the features of the free package</li>
        </ul>
      </Box>

    </>
  ),
};

export function StoreCard(props: Props) {
  const { selected, cardVariant, ...restProps } = props;
  const cardDef = cardVariant === "pro" ? proCard : freeCard;

  const papper = {
    backgroundColor: theme.palette.background.default,
    boxShadow: "1px 1px 8px 2px rgba(0, 0, 0, 0.20)",
    borderRadius: 4,
    position: "relative",
    padding: theme.spacing(1),
    overflowX: "auto",
    margin: "10px auto",
    width: 300,
    height: 400,
    border: `2px solid transparent`,
    "&::-webkit-scrollbar": {
      height: 6,
      width: 6,
    },
    "&::-webkit-scrollbar-track": {
      backgroundColor: "none",
    },
    "&::-webkit-scrollbar-thumb": {
      cursor: "pointer",
      borderRadius: 100,
      backgroundColor: "rgba(0, 0, 0, 0.2)",
    },
    overflowY: "hidden",
    cursor: "pointer",
  };

  const papperPro = {
    backgroundColor: "#F7F9FD",
  };

  const paperSelected = {
    border: `2px solid ${theme.palette.primary.main}`,
  };

  return (
    <Paper
      sx={{
        ...papper,
        ...(cardVariant === "pro" ? papperPro : undefined),
        ...(selected ? paperSelected : undefined),
      }}
      {...restProps}
    >
      <Stack
        direction="column"
        justifyContent="flex-start"
        alignItems="center"
        spacing={1.25}
      >
        <Box sx={{ height: 30 }}>{cardDef.logo}</Box>

        <Typography variant="body2" sx={{ fontSize: 12, height: 40 }}>
          {cardDef.subtitle}
        </Typography>

        <Stack direction="column" justifyContent="center" alignItems="center">
          <Typography variant="h6">{cardDef.price}</Typography>
          <Typography variant="body2" sx={{ fontWeight: 300 }}>
            {cardDef.priceSubtitle}
          </Typography>
        </Stack>
        <Box
          sx={{
            paddingLeft: theme.spacing(0.25),
            paddingRight: theme.spacing(0.25),

            fontSize: 10,
            fontWeight: 400,
            "& ul": {
              "& li": {
                listStyleType: "disc",
              },
            },
          }}
        >
          {cardDef.description}
        </Box>
      </Stack>
    </Paper>
  );
}
