import { useMediaQuery, useTheme } from "@mui/material";
import { useSearchParams } from "react-router-dom";

export const showFriendlyErrorFromHTMLCode = (
  error: any,
  default_value: string = "An error occured. Please try again."
): string => {
  return showFriendlyErrorFromData(error.response?.data, default_value);
};

export const showFriendlyErrorFromData = (
  data: any,
  default_value: string = "An error occured. Please try again."
): string => {
  if (data?.detail?.length > 0) {
    if (data?.detail[0].msg) return data?.detail[0].msg;
  }
  if (data?.detail) return data?.detail;
  return default_value;
};

export function useMediaMobile() {
  const theme = useTheme();
  return useMediaQuery(theme.breakpoints.down("md"));
}

export function useReferer() {
  const [searchParams] = useSearchParams();
  const ref = searchParams.get("ref");
  if (ref) return ref;
}

export function titleCase(s: string) {
  return s[0].toUpperCase() + s.slice(1).toLowerCase();
}
