import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  FormControl,
  FormHelperText,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import { TimePicker } from "@mui/x-date-pickers";
import dayjs, { Dayjs } from "dayjs";
import { DaysOfWeek } from "../interfaces/Agent";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import { useFormContext, Controller } from "react-hook-form";
import { IHomeFormInput } from "../interfaces/IHomeFormInput";

export interface AvailabilityAccordianProps {
  index: number;
  expanded: boolean;
  onChange: (event: React.SyntheticEvent, expanded: boolean) => void;
}

export default function AvailabilityAccordian(
  props: AvailabilityAccordianProps
) {
  const { index, expanded, onChange } = props;
  const timePickerProps = {};

  const titleCase = (s: string) => {
    return s[0].toUpperCase() + s.slice(1).toLowerCase();
  };

  const {
    control,
    formState: { errors },
  } = useFormContext<IHomeFormInput>();

  const getDateValue = (value: string | undefined): Dayjs | null => {
    if (dayjs(value, "HH:mm").isValid()) return dayjs(value, "HH mm");
    return null;
  };

  const handleDateChange = (field: any, time: Dayjs | null) => {
    let value = null;
    if (dayjs(time, "HH:mm").isValid()) value = time?.format("HH:mm");
    field.onChange(value);
  };

  const getError = (day: DaysOfWeek, fieldName: string) => {
    if (
      errors &&
      errors["agents"] &&
      errors["agents"][index] &&
      errors["agents"][index] !== undefined
    ) {
      const agentE: any = errors["agents"][index];
      return (
        agentE["availability"] &&
        agentE["availability"][day] &&
        (agentE["availability"][day] as any)[fieldName] &&
        (agentE["availability"][day] as any)[fieldName]["message"]
      );
    }
  };

  return (
    <Accordion
      slotProps={{ transition: { unmountOnExit: true } }}
      disableGutters
      elevation={0}
      square
      expanded={expanded === true}
      onChange={onChange}
    >
      <AccordionSummary
        expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
      >
        <Typography>Availability</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Stack
          direction="column"
          justifyContent="flex-start"
          alignItems="stretch"
          spacing={2}
        >
          {Object.values(DaysOfWeek).map((day) => (
            <Grid
              key={day}
              container
              justifyContent="center"
              alignItems="center"
              sx={{ width: "100%" }}
              columnSpacing={2}
            >
              <Grid item xs={4}>
                <Typography sx={{ paddingBottom: 2 }}>
                  {titleCase(day)}
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Controller
                  control={control}
                  name={`agents.${index}.availability.${day}.start`}
                  render={({ field }) => {
                    const e = getError(day, "start");
                    return (
                      <FormControl
                        sx={{ width: "100%" }}
                        error={e !== undefined}
                      >
                        <TimePicker
                          sx={timePickerProps}
                          label="From"
                          value={getDateValue(field.value)}
                          onChange={(time) => handleDateChange(field, time)}
                        />
                        <FormHelperText>
                          {e ? e.toString() : "　"}
                        </FormHelperText>
                      </FormControl>
                    );
                  }}
                />
              </Grid>
              <Grid item xs={4}>
                <Controller
                  control={control}
                  name={`agents.${index}.availability.${day}.end`}
                  render={({ field }) => {
                    const e = getError(day, "end");
                    return (
                      <FormControl
                        sx={{ width: "100%" }}
                        error={e !== undefined}
                      >
                        <TimePicker
                          sx={timePickerProps}
                          label="To"
                          value={getDateValue(field.value)}
                          onChange={(time) => handleDateChange(field, time)}
                        />
                        <FormHelperText>
                          {e ? e.toString() : "　"}
                        </FormHelperText>
                      </FormControl>
                    );
                  }}
                />
              </Grid>
            </Grid>
          ))}
        </Stack>
      </AccordionDetails>
    </Accordion>
  );
}
