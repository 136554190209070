import { TextField } from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";
import { IHomeFormInput } from "../../interfaces/IHomeFormInput";

export interface ModelCardProps {}

export function WelcomeSection() {
  const {
    control,
    formState: { errors },
  } = useFormContext<IHomeFormInput>();

  return (
    <Controller
      name="welcome_message"
      control={control}
      render={({ field }) => (
        <TextField
          {...field}
          error={errors.welcome_message !== undefined}
          helperText={errors.welcome_message ? errors.welcome_message?.message : "　"}
          className="rounded-md w-full !ring-transparent !outline-none focus:outline-none bg-white/10 focus:border-white/80 transition-all focus:bg-white/5"
          type="text"
          inputProps={{
            maxLength: 500,
          }}
        />
      )}
    />
  );
}
