import { yupResolver } from "@hookform/resolvers/yup";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  Box,
  Button,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import dayjs from "dayjs";
import { isValidPhoneNumber } from "libphonenumber-js";
import React, { useContext, useEffect, useRef, useState } from "react";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { array, bool, object, string } from "yup";
import { RegisterDialog } from "../components/RegisterDialog";
import { CompanySection } from "../components/section/CompanySection";
import { ModelCardSection } from "../components/section/ModelCardSection";
import { TeamAccordianSection } from "../components/section/TeamAccordianSection";
import { VoiceSection } from "../components/section/VoiceSection";
import { WelcomeSection } from "../components/section/WelcomeSection";
import ApiContext from "../context/ApiContext";
import AuthContext from "../context/AuthContext";
import { LoadingCircle } from "../img/LoadingCircle";
import { RightArrow } from "../img/RightArrow";
import { AccessLevel } from "../interfaces/IGetUserResult";
import { IHomeFormInput } from "../interfaces/IHomeFormInput";
import { default_welcome } from "../utils/consts";

const availabilityItemSchema = {
  start: string().notRequired(),
  end: string()
    .notRequired()
    .test((end, ctx) => {
      const start = ctx.parent.start;
      if ((start && !end) || (!start && end))
        return ctx.createError({
          message: "Both a start and end time must be provided",
        });

      if (start && end && dayjs(start, "HH:mm").isAfter(dayjs(end, "HH:mm"))) {
        return ctx.createError({
          message: "Start time must be before end time",
        });
      }
      return true;
    }),
};

const availabilitySchema = {
  monday: object(availabilityItemSchema).notRequired(),
  tuesday: object(availabilityItemSchema).notRequired(),
  wednesday: object(availabilityItemSchema).notRequired(),
  thursday: object(availabilityItemSchema).notRequired(),
  friday: object(availabilityItemSchema).notRequired(),
  saturday: object(availabilityItemSchema).notRequired(),
  sunday: object(availabilityItemSchema).notRequired(),
};

const agentSchema = {
  first_name: string().required("First name is required."),
  last_name: string().required("Last name is required."),
  phone: string()
    .required("Phone number is required.")
    .test((phone, ctx) => {
      if (!isValidPhoneNumber(phone, "CA"))
        return ctx.createError({
          message: `${phone} is not a valid phone number`,
        });
      return true;
    }),
  email: string()
    .notRequired()
    .matches(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,}$/, {
      message: "Please enter a valid email address.",
      excludeEmptyString: true,
    }),
  is_away: bool(),
  availability: object().shape(availabilitySchema).notRequired(),
};

const cardsSchema = {
  title: string().required("Model card missing title"),
  body: string()
    .required("Model card missing body")
    .max(
      10000,
      "The total length of all instructions must not be longer than 10000 characters"
    ),
};

export const schema: any = object<IHomeFormInput>({
  company_name: string().required("Business name field is required."),
  voice: string().required("Voice field is required."),
  agents: array()
    .min(1, "Oops. Please add at least one user for your system.")
    .of(object().shape(agentSchema)),
  cards: array().of(object().shape(cardsSchema)),
  welcome_message: string().notRequired(),
});

export default function HomeView() {
  const navigate = useNavigate();
  const {
    data: { authToken },
    actions: { expireAt },
  } = useContext(AuthContext);
  const {
    data: {
      accessLevel,
      did,
      company_name,
      cards,
      welcome_message,
      defaultCards,
      agents,
      voice,
      lastWarning,
    },
    actions: { updateUser, setLastWarning },
  } = useContext(ApiContext);

  const modelAccordianRef = useRef<HTMLDivElement | null>();
  const [modelAccordian, setModelAccordian] = useState(false);
  const teamAccordianRef = useRef<HTMLDivElement | null>();
  const [teamAccordian, setTeamAccordian] = useState(false);
  const [availableAccordian, setAvailableAccordian] = useState<boolean[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string>("");
  const [success, setSuccess] = useState("");
  const [guestTime, setGuestTime] = useState(0);
  const [isRegOpen, setIsRegOpen] = useState(false);

  const defaultValues: IHomeFormInput = {
    company_name: company_name,
    voice: voice,
    cards: cards?.length >= 1 ? cards : defaultCards,
    agents: agents,
    welcome_message: welcome_message || default_welcome(),
  };

  const methods = useForm<IHomeFormInput>({
    resolver: yupResolver<IHomeFormInput>(schema),
    defaultValues,
  });
  const {
    handleSubmit,
    setValue,
    formState: { errors },
  } = methods;

  useEffect(() => {
    setValue("company_name", company_name);
  }, [company_name, setValue]);
  useEffect(() => {
    setValue("voice", voice);
  }, [setValue, voice]);
  useEffect(() => {
    setValue("cards", cards?.length >= 1 ? cards : defaultCards);
  }, [cards, defaultCards, setValue]);
  useEffect(() => {
    setValue("agents", agents);
  }, [agents, setValue]);
  useEffect(() => {
    if (welcome_message) setValue("welcome_message", welcome_message);
  }, [welcome_message, setValue]);

  const formatPhoneNumber = (phoneNumber: string) =>
    phoneNumber?.replace(/(\d{3})(\d{3})(\d{4})/, "$1-$2-$3");

  const onSubmit: SubmitHandler<IHomeFormInput> = (data: IHomeFormInput) => {
    const { company_name, voice, cards, agents, welcome_message } = data;

    setSuccess("");

    setLoading(true);

    updateUser(company_name, voice, cards, agents, welcome_message)
      .then(() => {
        const exp = new Array(agents.length).fill(false);
        setAvailableAccordian(exp);
        setModelAccordian(false);
        setTeamAccordian(false);
        setError("");
        setSuccess(
          "Success! Your changes have been immediately saved and are now live"
        );
      })
      .catch((error) => {
        if (
          error.response?.data?.detail &&
          typeof error.response?.data?.detail === "string"
        ) {
          setError(error.response.data.detail);
        } else {
          setError("An error occured. Please try again.");
        }

        console.error(error.response?.data);
      })
      .finally(() => {
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });

        setLoading(false);
      });
  };

  const onAvailabilityChange = (
    index: number,
    e: React.SyntheticEvent,
    expand: boolean
  ) => {
    e.stopPropagation();
    const exp = [...availableAccordian];
    while (exp.length < index) {
      exp.push(false);
    }
    exp[index] = expand;
    setAvailableAccordian(exp);
  };

  useEffect(() => {
    let timeout: NodeJS.Timeout | undefined = undefined;
    const isGuest = accessLevel === AccessLevel.GUEST;
    if (isGuest) {
      const diff = expireAt(authToken) - Date.now();
      const totalMinutes = Math.max(0, Math.floor(diff / (60 * 1000)));
      setGuestTime(totalMinutes);

      timeout = setTimeout(() => {
        const diff = expireAt(authToken) - Date.now();
        const totalMinutes = Math.max(0, Math.floor(diff / (60 * 1000)));
        setGuestTime(totalMinutes);
      }, 60 * 1000);
    }
    return () => {
      clearTimeout(timeout);
    };
  }, [authToken, expireAt, accessLevel]);

  useEffect(() => {
    if (lastWarning) setTimeout(() => setLastWarning(""), 10 * 1000);
  }, [lastWarning, setLastWarning]);

  return (
    <Paper className="p-4" elevation={1} sx={{ width: "100%" }}>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Stack
            direction="column"
            sx={{ maxWidth: 1280, margin: "auto", minHeight: "80vh" }}
            spacing={2}
          >
            {success && <Alert severity="success">{success} </Alert>}
            {lastWarning && <Alert severity="warning">{lastWarning} </Alert>}
            {accessLevel === AccessLevel.GUEST && (
              <>
                <RegisterDialog
                  open={isRegOpen}
                  onClose={(reason) => {
                    if (reason) {
                      setSuccess(reason);
                    }
                    setIsRegOpen(false);
                  }}
                />

                <Alert
                  onClick={() => setIsRegOpen(true)}
                  severity="warning"
                  sx={{ cursor: "pointer" }}
                >
                  {guestTime > 0
                    ? `This account expires in ${guestTime} minutes.`
                    : "This account has expired"}{" "}
                  To keep your data active, <u>click here</u> to upgrade your
                  account.
                </Alert>
              </>
            )}
            {accessLevel === AccessLevel.FREE &&
              process.env.REACT_APP_STRIPE_ENABLE === "true" && (
                <>
                  <Alert
                    onClick={() => navigate("/store")}
                    severity="info"
                    sx={{ cursor: "pointer" }}
                  >
                    Upgrade your free account to Pro. <u>Click here</u> to check
                    out all the great benefits of OpenVoice Pro.
                  </Alert>
                </>
              )}

            {error && <Alert severity="error">{error}</Alert>}
            <Box>
              <Typography variant="h3">
                Your number: {did && formatPhoneNumber(did)}
              </Typography>
            </Box>

            <Typography variant="h6" sx={{ marginBottom: 1 }}>
              Your business name
            </Typography>

            <Box>
              <CompanySection />
            </Box>

            <Typography variant="h6" sx={{ marginBottom: 1 }}>
              Choose your receptionist
            </Typography>
            <Box>
              <VoiceSection />
            </Box>

            <Typography variant="h6" sx={{ marginBottom: 1 }}>
              Your welcome message
            </Typography>

            <Box>
              <WelcomeSection />
            </Box>
            <Stack direction="column" spacing={0}>
              <Accordion
                slotProps={{ transition: { unmountOnExit: true } }}
                disableGutters
                elevation={0}
                square
                expanded={modelAccordian}
                onChange={(e, isExpanded) => {
                  setModelAccordian(isExpanded);
                  if (isExpanded)
                    setTimeout(() => {
                      modelAccordianRef.current?.scrollIntoView({
                        behavior: "smooth",
                        block: "center",
                      });
                    }, 500);
                }}
              >
                <AccordionSummary
                  aria-controls="panel2-content"
                  id="panel2-header"
                  expandIcon={
                    <ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />
                  }
                >
                  <Stack
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                  >
                    <Typography variant="h6" sx={{ margin: 0 }}>
                      Model instructions
                    </Typography>
                  </Stack>
                </AccordionSummary>
                <AccordionDetails>
                  {accessLevel !== AccessLevel.PRO &&
                    process.env.REACT_APP_STRIPE_ENABLE === "true" && (
                      <Alert
                        onClick={() => navigate("/store")}
                        severity="warning"
                        sx={{ cursor: "pointer", margin: 2 }}
                      >
                        This feature is limited to Pro accounts.{" "}
                        <u>Click here</u> to unlock.
                      </Alert>
                    )}
                  {accessLevel === AccessLevel.GUEST &&
                    process.env.REACT_APP_STRIPE_ENABLE !== "true" && (
                      <Alert
                        onClick={() => setIsRegOpen(true)}
                        severity="warning"
                        sx={{ cursor: "pointer", margin: 2 }}
                      >
                        This feature is limited. <u>Click here</u> to unlock.
                      </Alert>
                    )}

                  <Box ref={modelAccordianRef} sx={{ width: "100%" }}>
                    <ModelCardSection />
                  </Box>
                </AccordionDetails>
              </Accordion>
              <Accordion
                slotProps={{ transition: { unmountOnExit: true } }}
                disableGutters
                elevation={0}
                square
                expanded={teamAccordian}
                onChange={(e, isExpanded) => {
                  setTeamAccordian(isExpanded);
                  if (isExpanded)
                    setTimeout(() => {
                      teamAccordianRef.current?.scrollIntoView({
                        behavior: "smooth",
                        block: "center",
                      });
                    }, 500);
                }}
              >
                <AccordionSummary
                  aria-controls="panel2-content"
                  id="panel2-header"
                  expandIcon={
                    <ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />
                  }
                >
                  <Typography variant="h6" sx={{ margin: 0 }}>
                    List your team
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Box ref={teamAccordianRef} sx={{ width: "100%" }}>
                    <TeamAccordianSection
                      expanded={availableAccordian}
                      onChange={onAvailabilityChange}
                    />
                  </Box>
                </AccordionDetails>
              </Accordion>
            </Stack>
            <Stack
              direction="row-reverse"
              justifyContent="space-between"
              alignItems="flex-end"
              spacing={2}
            >
              <Button
                variant="contained"
                type="submit"
                sx={{ width: { xs: "100%", md: 200 } }}
              >
                Submit
                {loading ? (
                  <LoadingCircle />
                ) : (
                  <div className="flex items-center justify-center w-3 ml-1">
                    <RightArrow />
                  </div>
                )}
              </Button>
            </Stack>
          </Stack>
        </form>
      </FormProvider>
    </Paper>
  );
}
